export function useMutationObserver(
  target: Ref<HTMLElement | null>,
  callback: MutationCallback,
  options?: MutationObserverInit,
) {
  let observer: MutationObserver | null = null;

  const watchStop = watch(target, (newVal) => {
    if (newVal) {
      observer = new MutationObserver(callback);
      observer.observe(newVal, options);
    }
  });

  onUnmounted(() => {
    watchStop();
    if (observer) {
      observer.disconnect();
      observer = null;
    }
  });
}

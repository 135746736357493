import { pcSlots, spSlots } from '~/constants/googlePublisherTagSlots';

export function useGooglePublisherTag() {
  function initialize() {
    window.googletag = window.googletag ?? { cmd: [] };

    googletag.cmd.push(() => {
      const mapping = googletag
        .sizeMapping()
        .addSize([768, 0], [[300, 250]])
        .addSize(
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        )
        .build();

      [...pcSlots, ...spSlots].forEach((slotData) => {
        const slot = googletag
          .defineSlot(slotData.adUnitPath, slotData.sizes, slotData.id)
          ?.addService(googletag.pubads());

        if (slot && mapping) {
          slot.defineSizeMapping(mapping);
        }
      });

      googletag.pubads().enableSingleRequest();
      googletag.pubads().setCentering(false);
      googletag.enableServices();
    });
  }

  function display(id: string) {
    googletag.cmd.push(() => {
      googletag.display(id);
    });
  }

  function refresh() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  return {
    initialize,
    display,
    refresh,
  };
}

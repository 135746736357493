<script lang="ts" setup>
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  target: {
    type: String as PropType<'pc' | 'sp'>,
    required: true,
  },
});

const { display } = useGooglePublisherTag();
const { isFromNavel } = useUserAgentState();
const { screenSize } = useScreenSize();

const googleAdDivRef = ref<HTMLElement | null>(null);

const isDisplay = computed(() => {
  if (isFromNavel.value) {
    return false;
  }

  return props.target === screenSize.value;
});

watch(
  isDisplay,
  (newVal) => {
    if (newVal) {
      // NOTE: 広告表示用のdivのマウントされたあとに `display` を呼びたいので nextTick を使う
      nextTick(() => {
        display(props.id);
      });
    }
  },
  {
    immediate: true,
  },
);

useMutationObserver(googleAdDivRef, clearStyleIfNeeded, { attributes: true });

// 外部スクリプトによるスタイルの変更を検知したとき、都合の悪いスタイルをクリアする
function clearStyleIfNeeded(mutations: MutationRecord[]) {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
      if (
        googleAdDivRef.value?.style.marginLeft !== '' ||
        googleAdDivRef.value?.style.marginRight !== ''
      ) {
        googleAdDivRef.value?.style.removeProperty('margin-left');
        googleAdDivRef.value?.style.removeProperty('margin-right');
      }
    }
  });
}
</script>

<template>
  <ClientOnly>
    <div
      v-if="isDisplay"
      :id="id"
      ref="googleAdDivRef"
      class="min-w-[300px] min-h-[250px]"
    />
  </ClientOnly>
</template>

type Slot = {
  // 広告とdivのマッピングに使うID。任意の文字列でよい
  id: string;
  adUnitPath: string;
  sizes: [number, number][];
};

export const pcSlots: Slot[] = [
  {
    id: 'div-gpt-ad-1534157071878-0',
    adUnitPath: '/21153358,51956376/50275',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157096547-0',
    adUnitPath: '/21153358,51956376/50276',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157132108-0',
    adUnitPath: '/21153358,51956376/50277',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157160805-0',
    adUnitPath: '/21153358,51956376/50390',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157182002-0',
    adUnitPath: '/21153358,51956376/50391',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157217429-0',
    adUnitPath: '/21153358,51956376/50392',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157250784-0',
    adUnitPath: '/21153358,51956376/50393',
    sizes: [[300, 250]],
  },
  {
    id: 'div-gpt-ad-1534157276765-0',
    adUnitPath: '/21153358,51956376/50394',
    sizes: [[300, 250]],
  },
];

export const spSlots: Slot[] = [
  {
    id: 'div-gpt-ad-1534156907495-0',
    adUnitPath: '/21153358,51956376/50280',
    sizes: [
      [300, 250],
      [336, 280],
    ],
  },
  {
    id: 'div-gpt-ad-1534156947870-0',
    adUnitPath: '/21153358,51956376/50281',
    sizes: [
      [300, 250],
      [336, 280],
    ],
  },
  {
    id: 'div-gpt-ad-1534156980762-0',
    adUnitPath: '/21153358,51956376/50282',
    sizes: [
      [300, 250],
      [336, 280],
    ],
  },
  {
    id: 'div-gpt-ad-1534156853557-0',
    adUnitPath: '/21153358,51956376/50278',
    sizes: [
      [300, 250],
      [336, 280],
    ],
  },
  {
    id: 'div-gpt-ad-1534156880642-0',
    adUnitPath: '/21153358,51956376/50279',
    sizes: [
      [300, 250],
      [336, 280],
    ],
  },
  {
    id: 'div-gpt-ad-1476788680015-5',
    adUnitPath: '/62539033/SPWeb下部固定バナー',
    sizes: [
      [320, 50],
      [320, 100],
    ],
  },
];

<template>
  <div class="border-gray-300 border rounded">
    <h2 :class="headingClass">{{ heading }}</h2>
    <div><slot /></div>
  </div>
</template>

<script lang="ts">
export const colors = [null, 'facebook', 'x', 'cream'] as const;
</script>

<script lang="ts" setup>
type Color = (typeof colors)[number];

const props = defineProps({
  heading: {
    required: true,
    type: String,
  },
  color: {
    type: String as PropType<Color>,
    default: null,
  },
});

const headingClass = computed(() => {
  return {
    'font-bold py-3 px-4 border-gray-300 border-b': true,
    'bg-[#3a5897] text-white': props.color === 'facebook',
    'bg-[#55acee] text-white': props.color === 'x',
    'bg-cream-300': props.color === 'cream',
  };
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    x="0"
    y="0"
    style="enable-background: new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
  >
    <switch>
      <g>
        <path
          d="M256 398c78.3 0 142-63.7 142-142s-63.7-142-142-142-142 63.7-142 142 63.7 142 142 142zm0-264c67.3 0 122 54.7 122 122s-54.7 122-122 122-122-54.7-122-122 54.7-122 122-122zM506.7 1.2c-3.3-1.7-7.2-1.5-10.3.5C447.3 34.9 418 90.1 418 149.3v129.8c0 13.9 5.4 27 15.3 36.8l18.7 18.7V482c0 16.5 13.5 30 30 30s30-13.5 30-30V10c0-3.7-2-7.1-5.3-8.8zM492 482c0 5.5-4.5 10-10 10s-10-4.5-10-10V330.5c0-2.7-1.1-5.2-2.9-7.1l-21.7-21.7c-6.1-6.1-9.4-14.1-9.4-22.7V149.3c0-46 19.9-89.2 54-119V482z"
          class="st0"
        />
        <path
          d="M184.1 256c0-28.9 17.2-54.8 43.7-66.2 5.1-2.2 7.4-8 5.3-13.1-2.2-5.1-8-7.4-13.1-5.3-33.9 14.5-55.9 47.7-55.9 84.6 0 5.5 4.5 10 10 10s10-4.5 10-10zM255.6 184.1h.4c5.5 0 10-4.5 10-10s-4.5-10-10-10h-.5c-5.5 0-10 4.5-9.9 10.1 0 5.4 4.5 9.9 10 9.9zM178.5 103.4c24.1-12.3 50.2-18.5 77.5-18.5 47.4 0 91.5 18.9 124.1 53.3 2 2.1 4.6 3.1 7.3 3.1 2.5 0 4.9-.9 6.9-2.7 4-3.8 4.2-10.1.4-14.1-17.7-18.6-38.5-33.3-61.9-43.5-24.2-10.6-50-16-76.7-16-30.5 0-59.6 7-86.6 20.7-4.9 2.5-6.9 8.5-4.4 13.4 2.4 4.9 8.5 6.8 13.4 4.3zM404.3 360.4c-4.3-3.4-10.6-2.7-14 1.6-32.7 41.4-81.6 65.1-134.3 65.1-32.9 0-64.9-9.4-92.5-27.1-4.6-3-10.8-1.6-13.8 3s-1.6 10.8 3 13.8c30.8 19.8 66.5 30.3 103.3 30.3 58.8 0 113.5-26.5 150-72.7 3.4-4.3 2.7-10.6-1.7-14zM135.8 377.8l-.2-.2c-3.6-4.2-9.9-4.8-14.1-1.2-4.2 3.6-4.8 9.9-1.2 14.1l.1.1c.1.1.2.3.3.4 2 2.3 4.8 3.5 7.6 3.5 2.3 0 4.6-.8 6.5-2.4 4.2-3.6 4.7-9.9 1.1-14.1l-.1-.2zM134 132V10c0-5.5-4.5-10-10-10s-10 4.5-10 10v108H96V10c0-5.5-4.5-10-10-10S76 4.5 76 10v108H58V10c0-5.5-4.5-10-10-10S38 4.5 38 10v108H20V10c0-5.5-4.5-10-10-10S0 4.5 0 10v122c0 24.5 14.9 46.7 37 56.2V482c0 16.5 13.5 30 30 30s30-13.5 30-30V188.2c22.1-9.5 37-31.7 37-56.2zm-49.8 39.6c-4.3 1.2-7.2 5.2-7.2 9.6V482c0 5.5-4.5 10-10 10s-10-4.5-10-10V181.2c0-4.5-2.9-8.4-7.2-9.6-15.6-4.5-27-17.9-29.3-33.6h93.1c-2.4 15.7-13.8 29.1-29.4 33.6z"
          class="st0"
        />
      </g>
    </switch>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #e8e3e1;
}
</style>
